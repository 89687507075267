export function makeSlashWrap(aString) {
  return aString.replace('/', '/&#8203;');
}


export function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export function sanitize(aString) {
  if (aString == 'null') {
    aString == '';
  }
  return aString || '';
}

export function linkify(text) {
  text = sanitize(text);
  var urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
  return text.replace(urlRegex, function (url) {
    return '<a href="' + url + '" target="_blank">' + url + '</a>';
  });
}

export function mailify(text) {
  var email_regex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi;
  var result = text.replace(email_regex, '<a href="mailto:$1">$1</a>');
  return result; // This two lines are enough.
}


export function iso2GermanDate(anIsoDateString) {
  return anIsoDateString.replace(/(\d{4})-(0?\d+)-(0?\d+)/, '$3.$2.$1');
}
