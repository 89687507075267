// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';
import {makeSlashWrap, getParameterByName, sanitize, linkify, mailify, iso2GermanDate} from "./utils";

var baseUrl = '';
var IS_DEV = false,
  SORT_DIRECTION_ASC = 'asc',
  SORT_DIRECTION_DESC = 'desc',
  JSON_LOAD_PATH = 'json/sample.json',
  //JSON_LOAD_PATH = 'https://orgawahlen.testverdi.de/api',
  JSON_LOAD_PATH_SAMPLE = 'json/sample_220124.json',
  JSON_LOAD_PATH_SAMPLE = 'json/sample.json',
  JSON_LOAD_PATH = 'https://orgawahlen.verdi.de/api',
  REGION_KEYS = ['LBZ', 'OrgaBezirk', 'OrgaOrtsverein'],
  REGION_NAME = 'name',
  ORGA_KEYS = ['FB', 'OrgaFachgruppe'],
  ORGA_NAME = 'name',
  ELCTION_IDS = 'ids_wahlen',
  BLANK = '---';

if (
    window.location.href.indexOf('localhost') != -1 ||
    window.location.href.indexOf('use_dummy_data') != -1) {
  JSON_LOAD_PATH = JSON_LOAD_PATH_SAMPLE;
}

var ELECTION_COLUMN_DATE_START = 'Datum_Beginn';
var ELECTION_COLUMN_DATE_END = 'Datum_Ende';
var ELECTION_COLUMN_TIME_START = 'Uhrzeit_Beginn';
var ELECTION_COLUMN_TIME_END = 'Uhrzeit_Ende';
var ELECTION_COLUMN_NAME = 'Name';
var ELECTION_COLUMN_TYPE = 'Gremienarten';
var ELECTION_COLUMN_LOCATION = 'Veranstaltungsort';
var ELECTION_COLUMN_ROOM = 'Raum';
var ELECTION_COLUMN_CITY = 'Ort';
var ELECTION_COLUMN_ID = 'id';
var ELECTION_COLUMN_STREET = 'Strasse';
var ELECTION_COLUMN_BOARD_TYPES = 'Gremienarten';
var ELECTION_COLUMN_BRANCHES = 'Geschäftsstellen';
var ELECTION_COLUMN_PROFESSIONAL_GROUP = 'Fachgruppe';
var ELECTION_COLUMN_LOCAL_ASSOCIATION = 'Ortsverein';
var ELECTION_COLUMN_ZIP = 'Postleitzahl';
var ELECTION_COLUMN_COMPANY = 'Betrieb';
var ELECTION_COLUMN_EXPLANATION = 'Erlaeuterung_zu_TN_Kreis_Text';
var ELECTION_COLUMN_APPLICATION_DEADLINE = 'Antragsschluss';

var initDone = false, initRWDTableDone = false;

var GROUP_KEY = 'Gruppen';
//var $ = require('jquery');
//var ts = require('tablesorter');
//var tsp = require('tablesorter-pagercontrols');
var json;
var $regionSelect0, $regionSelect1, $regionSelect2;
var $orgaSelect0, $orgaSelect1;
var $groupSelect0;
var cache = {};

// polyfill-shims
if (!Date.now) {
  Date.now = function now() {
    return new Date().getTime();
  };
}

// utils


function processText(text) {
  text = sanitize(text);
  text = linkify(text);
  text = mailify(text);
  return text;
}


function c(t, optional) {
  if (!IS_DEV) {
    return;
  }

  if (!optional) {
    console.log(t);
    return;
  }
  if (typeof t === 'object' || typeof (t) === 'function') {
    console.log(optional + ': ');
    console.log(t);
  } else {
    console.log(optional + ': ' + t);
  }
}

function doTableSort() {
  if (!initDone) {
    $('.result__table').tablesorter({
      sortList: [[1, 0]]
    }).tablesorterPager({
      container: $('.pager'),
      pager_size: 20,
      savePages: false,
      size: 20,
      output: '{startRow} – {endRow} von {totalRows} Ergebnissen ', // '{page}/{totalPages}'
    });
    initDone = true;
  } else {
    $('.result__table').trigger('updateAll');
  }
}

function makeTableResponsive() {
  if (true || !initRWDTableDone) {
    $('.result__table').ngResponsiveTables();
    initRWDTableDone = true;
  }
}

// return an array of names representing the path for the given select
function pathForSelect($select) {
  var nodeNames = [$select.val()];
  var parent = $select.prev();
  while (parent.length) {
    nodeNames.push(parent.val());
    parent = parent.prev();
  }
  return nodeNames;
}

function renderTableRow(dataObject) {
  var rc = '<tr data-id="' + dataObject['id'] + '">';
  //rc += '<td>' + dataObject['id'] + '</td>';
  rc += '<td ><a href="detail.html?id=' + dataObject['id'] + '"> ' + (dataObject[ELECTION_COLUMN_NAME] || '') + '</a></td>';
  rc += '<td data-text="' + dataObject[ELECTION_COLUMN_DATE_START] + '"><a href="detail.html?id=' + dataObject['id'] + '">' + iso2GermanDate(dataObject[ELECTION_COLUMN_DATE_START]) + '</a></td>';
  //rc += '<td>' + dataObject[ELECTION_COLUMN_TIME_START] + '</td>';
  //rc += '<td>' + dataObject[ELECTION_COLUMN_TYPE] + '</td>';
  //1rc += '<td>' + dataObject[ELECTION_COLUMN_CITY] + '</td>';
  rc += '<td ><a href="detail.html?id=' + dataObject['id'] + '">' + (departmentForElectionId(dataObject[ELECTION_COLUMN_ID]) || '') + '</a></td>'; // department fachbereich
  rc += '<td><a data-fachgruppe href="detail.html?id=' + dataObject['id'] + '">' + (sectionForElectionId(dataObject[ELECTION_COLUMN_ID]) || dataObject[ELECTION_COLUMN_PROFESSIONAL_GROUP] || '') + '</a></td>'; // section fachgruppe
  //rc += '<td></td>'; // level/Ebene
  rc += '<td><a href="detail.html?id=' + dataObject['id'] + '">' + makeSlashWrap(nationalDistrictForElectionId(dataObject[ELECTION_COLUMN_ID]) || '') + '</a></td>';  // district/Bezirk
  rc += '<td><a href="detail.html?id=\' + dataObject[\'id\'] + \'">' + (districtForElectionId(dataObject[ELECTION_COLUMN_ID]) || '') + '</a></td>'; // Bezirk
  //rc += '<td>' + regionSectionForId(dataObject[ELECTION_COLUMN_ID]) + '</td>'; // Ortsverein
  rc += '<td><a href="detail.html?id=' + dataObject['id'] + '">' + groupForId(dataObject[ELECTION_COLUMN_ID]) + '</a></td>'; // Gruppe
  rc += '</tr>';
  return rc;
}


function fillNextSelect($select, keys) {
  // retrieve tree-path walking through previous selects
  var nodePath = pathForSelect($select);
  var node = nodeForPath(nodePath, keys, true);
  // fill next select
  var values = _.map(node, function (v) {
    return v[REGION_NAME];
  });

  fillSelectWithValues($select.next(), values);
}

function displayEntries() {
  var entriesToDisplay = filterEntries(json['Wahlen']);
  entriesToDisplay = sortEntries(entriesToDisplay, ELECTION_COLUMN_DATE_START, SORT_DIRECTION_ASC);

  setHeadline('Wahlen: ' + entriesToDisplay.length);
  renderTable(entriesToDisplay);
  attachHandlers();
}

function addEnrichLinkHandler() {
  $('table a').each(function () {
    $(this).data('original-href', $(this).attr('href'));
    $(this).on('click', function (e) {
      //e.preventDefault();
      $(this).attr('href', appendBackLink($(this).data('original-href')));
    })
  });
}

function appendBackLink(url) {
  return url + '&back_url=' + window.location.href;
}

function nodeForPath(nodeNames, keys, getnext) {
  if (!nodeNames.length) {
    return;
  }

  // iterate tree upon generated path
  var counter = 0, node = json;
  do {
    var currentName = nodeNames.pop();
    node = node[keys[counter]];
    c(node, 'Node:');
    node = _.find(node, function (o) {
      return o[REGION_NAME] === currentName;
    });
    c(node, 'Node2:');
    counter++;
  } while (nodeNames.length);
  counter = Math.min(keys.length - 1, counter);
  if (getnext) {
    return node[keys[counter]];
  } else {
    return node;
  }
}

function isDetailMode() {
  return $('.container--list').length == 0;
}

function setBackButton() {
  var back = getParameterByName('back_url');
  if (back) {
    $('[data-back-button]').attr('href', back);
  }
}

function initDetailView() {
  var id = getParameterByName('id');
  var election = electionForId(id);
  fillDetailTable(election);
  setBackButton();
}

function electionForId(anId) {
  var result;
  json['Wahlen'].forEach(function (o) {
    if (o['id'] == anId) {
      result = o;
    }
  });
  return result;
}


function createDetailTableContent(dataObject) {
  var result = '';

  var tableContent = [
    {
      label: 'Konferenz/&#8203;Versammlung',
      value: processText(dataObject[ELECTION_COLUMN_NAME]) || ''
    },
    /*{
      label: 'Name:',
      value: dataObject[ELECTION_COLUMN_NAME]
    },*/
    {
      label: 'Datum',
      value: dataObject[ELECTION_COLUMN_DATE_END] ? (iso2GermanDate(dataObject[ELECTION_COLUMN_DATE_START]) + ' - ' + iso2GermanDate(dataObject[ELECTION_COLUMN_DATE_END])) : iso2GermanDate(dataObject[ELECTION_COLUMN_DATE_START])
    },
    {
      label: 'Uhrzeit',
      value: dataObject[ELECTION_COLUMN_TIME_END] ? (dataObject[ELECTION_COLUMN_TIME_START] + ' - ' + dataObject[ELECTION_COLUMN_TIME_END]) : dataObject[ELECTION_COLUMN_TIME_START]
    },
    {
      label: 'Landesbezirk',
      value: nationalDistrictForElectionId(dataObject[ELECTION_COLUMN_ID])
    },
    {
      label: 'Bezirk',
      value: districtForElectionId(dataObject[ELECTION_COLUMN_ID])
    },
    {
      label: 'Fachbereich',
      value: departmentForElectionId(dataObject[ELECTION_COLUMN_ID])
    },
    {
      label: 'Fachgruppe',
      value: (dataObject[ELECTION_COLUMN_PROFESSIONAL_GROUP]) || '',
      Xvalue: professionalGroupForElectionId(dataObject[ELECTION_COLUMN_PROFESSIONAL_GROUP]),
    },
    {
      label: 'Ortsverein',
      value: processText(dataObject[ELECTION_COLUMN_LOCAL_ASSOCIATION]) || regionSectionForId(dataObject[ELECTION_COLUMN_ID])
    }, {
      label: 'Betrieb',
      value: processText(dataObject[ELECTION_COLUMN_COMPANY] || '')
    },
    {
      label: 'Erläuterung zu TN-Kreis',
      value: processText(dataObject[ELECTION_COLUMN_EXPLANATION] || '')
    },
    {
      label: 'Gruppe',
      value: groupForId(dataObject[ELECTION_COLUMN_ID])
    },
    {
      label: BLANK,
      value: BLANK
    },
    {
      label: ELECTION_COLUMN_LOCATION,
      value: processText(dataObject[ELECTION_COLUMN_LOCATION] || '')
    },
    {
      label: ELECTION_COLUMN_ROOM,
      value: processText(dataObject[ELECTION_COLUMN_ROOM])
    },
    {
      label: 'PLZ/Ort',
      value: (dataObject[ELECTION_COLUMN_ZIP] || '') + ' ' + (dataObject[ELECTION_COLUMN_CITY] || '')
    }, {
      label: ELECTION_COLUMN_STREET,
      value: dataObject[ELECTION_COLUMN_STREET] || ''
    }, {
      label: 'Antragsschluss',
      value: dataObject[ELECTION_COLUMN_APPLICATION_DEADLINE] ? iso2GermanDate(dataObject[ELECTION_COLUMN_APPLICATION_DEADLINE]) : ''
    }];
  tableContent.forEach(function (o) {
    if (o['value'] == BLANK) {
      result += '<tr class="detail-table__tr--no-line"><td colspan="2">&nbsp;</td></tr>';
    } else {
      result += '<tr>';
      result += '<th>' + o['label'] + ':</th><td>' + o['value'] + '</td>';
      result += '</tr>';
    }
  });
  return result;
}

function fillDetailTable(dataObject) {

  var result = createDetailTableContent(dataObject);
  $('.c-preloader').hide();
  $('.btn').removeClass('hidden');
  $('.detail-table').html(result).hide().fadeIn();
}

function initSelects(setupFromData = true) {
  // region Filter
  $regionSelect0 = $('.region-step0');
  $regionSelect1 = $('.region-step1');
  $regionSelect2 = $('.region-step2');
  $('.region-step1,.region-step2').prop('disabled', 'disabled')
    .addClass('is_disabled');
  if (setupFromData) {
    $.each(json[REGION_KEYS[0]], function (k, v) {
      $regionSelect0.append('<option>' + v[REGION_NAME] + '</option>');
    });
  }
  $regionSelect0.prop('selectedIndex', 0);
  fillSelectWithValues($regionSelect1, []);
  fillSelectWithValues($regionSelect2, []);

  // Struktur-Filter
  $orgaSelect0 = $('.orga-step0');
  $orgaSelect1 = $('.orga-step1');
  $orgaSelect1.prop('disabled', 'disabled')
    .addClass('is_disabled');
  if (setupFromData) {
    $.each(json[ORGA_KEYS[0]], function (k, v) {
      $orgaSelect0.append('<option>' + v[REGION_NAME] + '</option>');
    });
  }
  $orgaSelect0.prop('selectedIndex', 0);
  fillSelectWithValues($orgaSelect1, []);

  // Gruppen
  $groupSelect0 = $('.group-step0');
  if(setupFromData) {
    $.each(json[GROUP_KEY], function (k, v) {
      $groupSelect0.append('<option>' + v[REGION_NAME] + '</option>');
    });
  }
  $groupSelect0.prop('selectedIndex', 0);

  $('.city_filter').val('');


  sortAllSelectOptions();

}

function sortSelectOptions(selector, skip_first) {
  var options = (skip_first) ? $(selector + ' option:not(:first)') : $(selector + ' option');
  var arr = options.map(function (_, o) {
    return {t: $(o).text(), v: o.value, s: $(o).prop('selected')};
  }).get();
  arr.sort(function (o1, o2) {
    var t1 = o1.t.toLowerCase(), t2 = o2.t.toLowerCase();
    return t1 > t2 ? 1 : t1 < t2 ? -1 : 0;
  });
  options.each(function (i, o) {
    o.value = arr[i].v;
    $(o).text(arr[i].t);
    if (arr[i].s) {
      $(o).attr('selected', 'selected').prop('selected', true);
    } else {
      $(o).removeAttr('selected');
      $(o).prop('selected', false);
    }
  });
}


function updateOrgaSelects($select) {
  $select.nextAll('select').prop('selectedIndex', 0).prop('disabled', 'disabled').addClass('is_disabled');
  if ($select.prop('selectedIndex') > 0) {
    $select.next().prop('disabled', '').removeClass('is_disabled');
    fillNextSelect($select, ORGA_KEYS);
  }
}

function updateRegionSelects($select) {
  $select.nextAll('select').prop('selectedIndex', 0).prop('disabled', 'disabled').addClass('is_disabled');
  if ($select.prop('selectedIndex') > 0) {
    $select.next().prop('disabled', '').removeClass('is_disabled');
    fillNextSelect($select, REGION_KEYS);
  }
}

function fillSelectWithValues($select, values) {
  var optionTags = '<option>' + $select.children().first().val() + '</option>';
  _.forEach(values, function (o) {
    optionTags += '<option>' + o + '</option>';
  });
  $select.html('').html(optionTags);
}

function sortAllSelectOptions() {
  sortSelectOptions('.region-step0', true)
  sortSelectOptions('.region-step1', true)
  sortSelectOptions('.region-step2', true)
  sortSelectOptions('.orga-step0', true)
  sortSelectOptions('.orga-step1', true)
  sortSelectOptions('.group-step0', true)
}

function updateHistory() {
  var currentUrl = new URL(window.location);
  var region0 = $(".region-step0").val();
  var region1 = $(".region-step1").val();
  currentUrl.searchParams.set('region0', region0);
  currentUrl.searchParams.set('region1', region1);

  history.pushState({}, '', currentUrl);
}

function setHistoryTo(newUrl) {
  history.pushState({}, '', newUrl);
}

function resetHistory() {
  history.pushState({}, '', baseUrl);
}


function appendHandlers() {
  $('.reset').click(function (e) {
    e.preventDefault();
    initSelects(false);
    displayEntries();
  });

  $('.time-buttons button').click(function (e) {
    e.preventDefault();
    $('.time-buttons button').removeClass('btn-primary').addClass('btn-default');
    $(this).addClass('btn-primary');
    displayEntries();
  });

  $('.city_filter').keyup(function (e) {
    e.preventDefault();
    displayEntries();
  });

  $('.group-step0').change(function (e) {
    e.preventDefault();
    displayEntries();
  });

  $('.region-step0, .region-step1, .region-step2').change(function (e) {
    e.preventDefault();
    updateRegionSelects($(this));
    updateHistory()
    displayEntries();
    sortAllSelectOptions();
  });

  $('.orga-step0, .orga-step1').change(function (e) {
    e.preventDefault();
    updateOrgaSelects($(this));
    displayEntries();
    sortAllSelectOptions();
  });
}

function initFiltering(aJson) {
  initSelects();
  appendHandlers();
  displayEntries(aJson);
  //attachHandlers();
  //addEnrichLinkHandler();
}

function pathForSelects(arrayOfSelects) {
  var path = [];
  _.each(arrayOfSelects, function (o) {
    if (o.prop('selectedIndex') > 0) {
      path.push(o.val());
    }
  });
  return path;
}

function filterByRegion(electionsData) {
  if (!electionsData || electionsData.length === 0 || $regionSelect0.prop('selectedIndex') === 0) {
    return electionsData;
  }

  var path = pathForSelects([$regionSelect2, $regionSelect1, $regionSelect0]);
  var regionNode = nodeForPath(path, REGION_KEYS, false);
  var ids = regionNode[ELCTION_IDS];

  return _.filter(electionsData, function (o) {
    return _.includes(ids, o[ELECTION_COLUMN_ID]);
  });
}

function filterByOrga(electionsData) {
  if (!electionsData || electionsData.length === 0 || $orgaSelect0.prop('selectedIndex') === 0) {
    return electionsData;
  }

  var path = pathForSelects([$orgaSelect1, $orgaSelect0]);
  var orgaNode = nodeForPath(path, ORGA_KEYS, false);
  var ids = orgaNode[ELCTION_IDS];

  return _.filter(electionsData, function (o) {
    return _.includes(ids, o[ELECTION_COLUMN_ID]);
  });
}

function filterByGroup(electionsData) {
  if (!electionsData || electionsData.length == 0) {
    return electionsData;
  }

  var group, name;
  if ($groupSelect0.prop('selectedIndex') > 0) { // Looking for 'Fachbereich'
    name = $groupSelect0.val();
    group = json[GROUP_KEY];
  } else {
    return electionsData;
  }

  var groupNode = _.find(group, function (o) {
    return o[ORGA_NAME] == name;
  });
  var ids = groupNode[ELCTION_IDS];

  return _.filter(electionsData, function (o) {
    return _.includes(ids, o[ELECTION_COLUMN_ID]);
  });

}

function filterByTime(electionsData) {
  if (!electionsData || electionsData.length === 0) {
    return electionsData;
  }
  var $selectedButton = $('.filter-button.btn-primary');
  if ($selectedButton.hasClass('past')) {
    return _.filter(electionsData, function (o) {
      return Date.parse(o[ELECTION_COLUMN_DATE_START]) < Date.now();
    });
  } else if ($selectedButton.hasClass('future')) {
    return _.filter(electionsData, function (o) {
      return Date.parse(o[ELECTION_COLUMN_DATE_START]) >= Date.now();
    });
  } else {
    return electionsData;
  }
}

function filterByCity(electionsData) {
  if (!electionsData || electionsData.length == 0) {
    return electionsData;
  }
  var city = $('.city_filter').val();
  if (city.length > 1) {
    return _.filter(electionsData, function (o) {
      return _.startsWith(o[ELECTION_COLUMN_CITY].toLowerCase(), city.toLowerCase());
    });
  }
  return electionsData;
}

function filterEntries(electionsData) {
  electionsData = filterByRegion(electionsData);
  electionsData = filterByOrga(electionsData);
  electionsData = filterByGroup(electionsData);
  electionsData = filterByTime(electionsData);
  electionsData = filterByCity(electionsData);

  return electionsData;
}

function renderTable(sortedEntries) {
  $('.c-preloader').fadeOut();
  $('.c-filters,.pager').removeClass('is_hidden');
  $('.result').fadeIn();
  $('.result__table tbody').html('');
  $.each(sortedEntries, function (k, v) {
    $('.result__table tbody').append(renderTableRow(v));
    //attachHandlers();
  });
  makeTableResponsive();
  doTableSort();
}

function setHeadline(headlineText) {
  $('.result__headline').text(headlineText);
}

function hideDetailLayer() {
  $('.page-cover').addClass('is-hidden');
  $('html, body').removeClass('is-fixed');
}

function showDetailInLayer(eventId) {
  // open cover
  $('html, body').addClass('is-fixed');
  $('.page-cover').removeClass('is-hidden');

  $('.page-cover, [data-layer-close]').on('click', function (e) {
    if (e.target = this) {
      //e.stopProgagting();
      hideDetailLayer();
      resetHistory();
    }
  })

  // open Layer

  var election = electionForId(eventId);
  // display event data
  $('.page-cover .detail-table').html(createDetailTableContent(election));

}

function attachHandlers() {
  $('.result__table tbody tr a').css('cursor', 'pointer').off('click').on('click', function (e) {
    e.preventDefault();
    var theId = $(this).parents("tr").data('id');
    setHistoryTo($(this).attr('href'));
    showDetailInLayer(theId);
  });
}


function sortEntries(arrayOfHashs, orderColumn, orderDirection) {
  orderDirection = orderDirection || SORT_DIRECTION_ASC;
  var result = _.sortBy(arrayOfHashs, function (o) {
    return o[orderColumn];
  });

  if (orderDirection == SORT_DIRECTION_DESC) {
    return _.reverse(result);
  }
  return result;
}


function _retrieveNodeNamesForId(anId, aKey, src) {
  src = src || json;
  cache[anId] = cache[anId] || {};
  if (typeof (cache[anId][aKey]) == 'undefined') {
    cache[anId][aKey] = null;
    var result = [];
    $.each(src[aKey], function (k, v) {
      if ($.inArray(anId * 1, v[ELCTION_IDS]) != -1) {
        result.push(v['name']);
      }
    });
    if (result.length) {
      cache[anId][aKey] = result.join(', ');
    }
  }

  return cache[anId][aKey] || '';
}

function _retrieveNodesForId(anId, aKey, src) {
  src = src || json;
  cache[anId] = cache[anId] || {};
  if (typeof (cache[anId][aKey]) === 'undefined') {
    cache[anId][aKey] = null;
    var result = [];
    c(src, 'hey');
    $.each(src[aKey], function (k, v) {
      if ($.inArray(anId * 1, v[ELCTION_IDS]) != -1) {
        result.push(v);
      }
    });
    if (result.length) {
      cache[anId][aKey] = result;
    }
  }

  return cache[anId][aKey];
}

function nationalDistrictForElectionId(anId) {
  return _retrieveNodeNamesForId(anId, REGION_KEYS[0]);
}

function districtForElectionId(anId) {
  cache[anId] = cache[anId] || {};

  if (typeof (cache[anId]['district']) == 'undefined') {
    cache[anId]['district'] = null;
    var result = [];
    $.each(json[REGION_KEYS[0]], function (k, v) {
      $.each(v[REGION_KEYS[1]], function (ki, va) {
        if ($.inArray(anId * 1, va[ELCTION_IDS]) != -1) {
          result.push(va['name']);
        }
      });
    });
    if (result.length) {
      cache[anId]['district'] = result.join(', ');
    }
  }

  return cache[anId]['district'] || '';
}

function districtsForElectionId(anId) {
  var parent = _retrieveNodesForId(anId, REGION_KEYS[0]);
  c(parent, 'Parent:');
  var nodes = _retrieveNodesForId(anId, REGION_KEYS[1], parent);
  return _.map(nodes, function (o) {
    o.name;
  }).join(', ');
}

function regionSectionForId(anId) {
  cache[anId] = cache[anId] || {};
  if (typeof (cache[anId]['regionSection']) == 'undefined') {
    cache[anId]['regionSection'] = null;
    $.each(json[REGION_KEYS[0]], function (k, v) {
      $.each(v[REGION_KEYS[1]], function (ki, va) {
        $.each(va[REGION_KEYS[2]], function (g, f) {
          c(f['name']);
          if ($.inArray(anId * 1, f[ELCTION_IDS]) != -1) {
            cache[anId]['regionSection'] = f['name'];
          }
        });
      });
    });
  }
  return cache[anId]['regionSection'] || '';
}

function departmentForElectionId(anId) {
  return _retrieveNodeNamesForId(anId, ORGA_KEYS[0]);
}

function professionalGroupForElectionId(anId) {
  return _retrieveNodeNamesForId(anId, ORGA_KEYS[1]);
}

function explanationForId(anId) {
  return _retrieveNodeNamesForId(anId, EXPLANATION_KEY);
}

function sectionForElectionId(anId) {
  cache[anId] = cache[anId] || {};
  if (typeof (cache[anId]['section']) == 'undefined') {
    cache[anId]['section'] = null;
    $.each(json[ORGA_KEYS[0]], function (k, v) {
      $.each(v[ORGA_KEYS[1]], function (g, f) {
        if ($.inArray(anId * 1, f[ELCTION_IDS]) != -1) {
          cache[anId]['section'] = f['name'];
        }
      });
    });
  }
  return cache[anId]['section'] || '';
}

function groupForId(anId) {
  return _retrieveNodeNamesForId(anId, GROUP_KEY);
}

function jsonReady(incomingJson) {
  json = incomingJson.data;
  if (isDetailMode()) {
    initDetailView();
  } else {
    baseUrl = window.location.href;
    initFiltering(incomingJson);
  }
}

function loadJson() {
  $.getJSON(JSON_LOAD_PATH, jsonReady).fail(function () {
    c('Error');
  });
}

function init() {
  // load json
  loadJson();
}

$(function () {
  init();
});
